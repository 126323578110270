import React from "react";
import SideMenu from "../../components/SideMenu";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import { Link } from "react-router-dom";
import { useState } from "react";
import api from "../../../api";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const SustainabilityViewForm = (props) => {
  const [params, setParams] = useState({
    name: "",
    file: "",
    image_url: "",
    base64String: "",
  });
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  const handleChange = (e) => {
    setParams({ ...params, name: e.target.value });
  };

  const fetchData = () => {
    api
      .get(`/admin/sustainability-reports/${id}`)
      .then((res) => {
        console.log(res.data.data, "resss");
        setParams(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return (
    <div>
      <div className="flex bg-gray-100 admin bg-adminbg">
        <ToastContainer />
        <SideMenu></SideMenu>
        <div className="flex flex-col w-full h-screen overflow-y-hidden">
          <AdminHeader></AdminHeader>
          <div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
              <div>
                <div className="flex items-center breadcrumbs">
                  <Link
                    to="/admin/sustainability"
                    className="text-base text-blue"
                  >
                    Sustainability{" "}
                  </Link>{" "}
                  <svg
                    className="mx-3"
                    width="8"
                    height="12"
                    viewBox="0 0 13 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 18.25L10.75 10.125L2 2"
                      stroke="#5F7995"
                      strokeWidth="1.875"
                      strokeLinecap="square"
                    />
                  </svg>
                  <span className="text-base text-ThemeBlue font-WavehausBold">
                    {id ? "View" : "Add"} report/policy
                  </span>
                </div>

                <hr className="mt-2 border-borderColor" />

                <div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
                  <h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">
                    Basic Info
                  </h4>
                  <div className="grid grid-cols-2 mt-6">
                    <div className={`form-group w-full inline-block my-1 mr-2`}>
                      <input
                        type="text"
                        id="name"
                        value={params?.name}
                        onChange={handleChange}
                        noValidate
                        name="name"
                        className="form-group__input"
                        autoComplete="off"
                        placeholder=" "
                        disabled={true}
                      />
                      <label htmlFor="name" className="form-group__label">
                        Enter report/policy name
                      </label>
                    </div>
                    <div className="flex flex-col gap-10 ">
                      <div>
                        {" "}
                        <img src="/pdfIcon.svg" className="w-20" />
                      </div>
                      <div className="px-6 -mt-8">
                        <a
                          href={`${process.env.REACT_APP_BASE_URL || 'https://api.virupaksha.com/'}${params?.path}`}
                          target="_blank"
                          className="text-green underline"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SustainabilityViewForm;
