export const certInfo = [
  {
    name: "tga",
    icon: "/home/certifications/tga.png",
  },
  {
    name: "fda",
    icon: "/home/certifications/fda.svg",
  },
  {
    name: "edom",
    icon: "/home/certifications/edom.svg",
  },
  {
    name: "cofepris",
    icon: "/home/certifications/cofepris.svg",
  },
  {
    name: "iso1",
    icon: "/home/certifications/iso1.svg",
  },
  {
    name: "iso2",
    icon: "/home/certifications/iso2.svg",
  },
  {
    name: "iso3",
    icon: "/home/certifications/iso3.svg",
  },
  {
    name: "iso4",
    icon: "/home/certifications/iso4.svg",
  },
  {
    name: "iso5",
    icon: "/home/certifications/iso5.svg",
  },
  {
    name: "iso6",
    icon: "/home/certifications/certificate-2.svg",
  },
  {
    name: "iso52",
    icon: "/home/certifications/certificate-3.svg",
  },
  {
    name: "iso8",
    icon: "/home/certifications/certificate-4.svg",
  },
  {
    name: "iso9",
    icon: "/home/certifications/certificate-5.svg",
  },
  {
    name: "sustain",
    icon: "/home/certifications/sustain.png",
  },
];

export const unit1 = [
  {
    title: "Sy.No.10, Gaddapotharam Village, Jinnaram Mandal, Sangareddy Dist.",
    icon: "/home/addressPin.svg",
  },
  {
    title: "Year of Commencement: 2003 Area: Ac.6.15",
    icon: "/home/building.svg",
  },
  {
    title: "Current Capacity: 400 KL",
    icon: "/home/capacity.svg",
  },
];

export const unit2 = [
  {
    title: "Plot no 30 to 33 IDA Pashamylaram Patancheru, Sangareddy Dist.",
    icon: "/home/addressPin.svg",
  },
  {
    title: "Year of Commencement: 2010 Area: Ac.5.00",
    icon: "/home/building.svg",
  },
  {
    title: "Current Capacity: 300 KL",
    icon: "/home/capacity.svg",
  },
];

export const unit3 = [
  {
    title: "Plot no 100 (P2) & (P3), Gadwanthi vil, Humnabad, Bidar, Karnataka",
    icon: "/home/addressPin.svg",
  },
  {
    title: "Year of Commencement: 2019 Area: Ac.6.15",
    icon: "/home/building.svg",
  },
  {
    title: "Current Capacity: 40 KL",
    icon: "/home/capacity.svg",
  },
];

export const statsInfo = [
  {
    title: "Year Founded",
    icon: "/home/stats/rocket.svg",
    description: "2003",
  },
  {
    title: "Team",
    icon: "/home/stats/team.svg",
    description: "1000+",
  },
  {
    title: "Countries",
    icon: "/home/stats/globe.svg",
    description: "100+",
  },
  {
    title: "Customers",
    icon: "/home/stats/customers.svg",
    description: "300+",
  },
];

export const contactFields = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const regularExpression = (name, value) => {
  const regexList = [
    {
      name: "name",
      regex: /^[A-Za-z\s]*$/,
      maxLength: 35,
    },
    {
      name: "company",
      regex: /^[A-Za-z\s]*$/,
      maxLength: 35,
    },
    {
      name: "first_name",
      regex: /^[A-Za-z\s]*$/,
      maxLength: 30,
    },
    {
      name: "last_name",
      regex: /^[A-Za-z\s]*$/,
      maxLength: 30,
    },
    {
      name: "email",
      regex: /^[^\s]*$/,
      maxLength: 50,
    },
    {
      name: "phone",
      regex: /^\+?[0-9]*$/,
      maxLength: 13,
    },
    {
      name: "pin_code",
      regex: /^[0-9]*$/,
      maxLength: 6,
    },
    {
      name: "qty",
      regex: /^([1-9][0-9]*)?$/,
      maxLength: 10,
    },

    {
      name: "message",
      regex: /^[^]*$/,
      maxLength: 200,
    },
    {
      name: "description",
      regex: /^[^]*$/,
      maxLength: 200,
    },
  ];

  const validationRule = regexList.find((rule) => rule.name === name);

  if (!validationRule) {
    return true;
  }

  const { regex, maxLength } = validationRule;

  if (!regex.test(value)) {
    return false;
  }

  if (maxLength && value.length > maxLength) {
    return false;
  }

  return true;
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent;

  if (
    /Mobile|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(userAgent)
  ) {
    return "Mobile Device";
  } else if (/Tablet|iPad/i.test(userAgent)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};

export const whyToChooseUsIcons = [
  {
    name: "gear",
    icon: "gear.svg",
    activeIcon: "activeGear.svg",
    position: "absolute -top-5",
  },
  {
    name: "paw",
    icon: "paw.svg",
    activeIcon: "activePaw.svg",
    position: "absolute -right-5",
  },
  {
    name: "star",
    icon: "star.svg",
    activeIcon: "activeStar.svg",
    position: "absolute -bottom-5",
  },
  {
    name: "garage",
    icon: "garage.svg",
    activeIcon: "activeGarage.svg",
    position: "absolute -left-5",
  },
];

export const whyToChooseUsContent = [
  {
    title: "Regulatory Approvals",
    description:
      "Following the ICH guidelines have become bread and butter for our employees.",
  },
  {
    title: "Safe & Sustainable Operations",
    description:
      "We adopt sustainable strategies such as green chemistry, waste reduction, energy efficiency, responsible sourcing, eco-friendly packaging, and EPR in our operations.",
  },
  {
    title: "Operational Excellence",
    description:
      "Efficient utilization of resources, time, and labor so waste can be reduced and even eliminated.",
  },
  {
    title: "Manufacturing Capability",
    description:
      "We manufacture from the SMs giving us the highest advantage of not being dependent.",
  },
];

export const cards = [
  {
    title: "Regulatory Approvals",
    icon: "/home/cards/settings.svg",
  },
  {
    title: "Safe & Sustainable Operations",
    icon: "/home/cards/paw.svg",
  },
  {
    title: "Operational Excellence",
    icon: "/home/cards/shield.svg",
  },
  {
    title: "Manufacturing Capability",
    icon: "/home/cards/manufacture.svg",
  },
];
