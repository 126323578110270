

import React, { Component } from 'react';
import api from '../../../api/index'
import {Link} from 'react-router-dom'
import  browserHistory from '../../../config/browserHistory'
import AdminHeader from '../../components/AdminHeader/AdminHeader'
import SideMenu from '../../components/SideMenu'
import Moment from 'moment';
import $ from 'jquery';

class ProductAdminView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id:this.props.match.params.id,
			product:{
				name:'',
			category_slug:'active-pharmaceutical-ingredients-apis',
			drug_name:'',
			drug_type:'',
			appearance:'',
			cas_no:'',
			molecular_formula:'',
			molecular_weight:'',
			melt_point:'',
			product_status:'',
			product_users:'',
			image_url:'',
			}
		}
	}

	componentDidMount() {
		this.getProductById();
	}

	getProductById=()=>{
		api.get(`/admin/products/` + this.state.id).then(res=>{
			console.log(res.data)
			this.setState({product:res.data.data})
		}).catch(err => {
			console.log(err)
		})
	}

	render() {
		return (
			<div className="flex bg-gray-100 admin bg-adminbg">
			<SideMenu></SideMenu>
			<div className="flex flex-col w-full h-screen overflow-y-hidden">
			<AdminHeader></AdminHeader>
			<div className="flex flex-col w-full overflow-x-hidden border-t border-ThemeLightBlue">
            <main className="flex-grow w-full p-6 ">
				<div>
             
                <div className="flex items-center breadcrumbs">
                <Link to="/admin/product-list" className="text-base text-blue">Product List </Link>	<svg className="mx-3" width="8" height="12" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 18.25L10.75 10.125L2 2" stroke="#5F7995" strokeWidth="1.875" strokeLinecap="square"/>
</svg>
 <span className="text-base text-ThemeBlue font-WavehausBold">View Product Info</span>
                </div>

				
					<hr className="mt-2 border-borderColor"/>
					

                        <div className="w-full pr-0 my-6 lg:pr-2">
							<div className="px-6 bg-white border rounded-lg shadow-xl py-7 border-borderBlue">
								<h4 className="pb-2 text-base border-b border-gray text-ThemeBlue font-WavehausBold">Basic Info</h4>
								<div className='mt-4'>
                                    <p className="text-blue">Product Name:</p>
                                    <p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.name}</p>
                                </div>
								<div className="flex items-center justify-between mt-4 mb-4">
							
								
                                
                                <div>
                                    <p className="text-blue">Drug Type:</p>
                                    <p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.drug_type}</p>
                                </div>  
								<div>
                                    <p className="text-blue">Drug Name:</p>
                                    <p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.drug_name}</p>
                                </div>
                                <div    >
                                    <p className="text-blue">Status</p>
									<p className={this.state.product.status ? 'text-green' : 'text-red'}>{this.state.product.status ? 'Active' : 'DeActive'}</p>
                                </div> 
								<div>
                                    <p className="text-blue">Created On:</p>
                                    <p className="text-ThemeBlue font-WavehausSemiBold">{Moment(this.state.product.created_at).format('d MMM YYYY')}</p>
                                </div>
								
                                </div>
								<div>
										<p className="text-blue">Appearance</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.appearance}</p>
									</div> 
								<div className="flex items-center justify-between mt-4 mb-4">
							
									<div>
										<p className="text-blue">Cas no:</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.cas_no}</p>
									</div>
									<div>
										<p className="text-blue">Melt Point:</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.melt_point}</p>
									</div>
									<div>
										<p className="text-blue">Molecular formula:</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.molecular_formula}</p>
									</div>  
									<div>
										<p className="text-blue">Molecular Weight:</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.molecular_weight}</p>
									</div>
									
							
							</div>
							<div>
										<p className="text-blue">Product Status</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.product_status}</p>
									</div> 
									<div className='mt-4'>
										<p className="text-blue">Product users</p>
										<p className="text-ThemeBlue font-WavehausSemiBold">{this.state.product.product_users }</p>
									</div> 

									<div className='mt-4'>
										<img 
                  							src={`${process.env.REACT_APP_BASE_URL || 'https://api.virupaksha.com'}${this.state.product.image_url}`}
										    alt=""/>
									</div>

							

								
                            </div>
                        </div>
						
					</div>
					<div>

	</div>
				</main>
			</div>
			</div>
		
			</div>
		);
	}
}

export default ProductAdminView;
